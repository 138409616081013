import React, { useEffect, useState } from 'react';
import './App.css';
import CustomRoutes from './RoutesFolder/Routes'; // Adjust the path according to your project structure
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { ThemeProvider } from './components/ThemeContext'; // Correct the import path
import Loader from './components/Loader';
import Footer from './components/Footer';
import './tailwind.css';

function App() {
  // loader state
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/under-construction') {
      setIsLoading(false);
    } else {
      const fakeDataFetch = () => {
        setTimeout(() => {
          setIsLoading(false);
        }, 4000);
      };

      fakeDataFetch();
    }
  }, [location]);

  return (
    <ThemeProvider>
      <div className="App">
        {isLoading && location.pathname !== '/under-construction' ? (
          <Loader />
        ) : (
          <CustomRoutes />
        )}
        {!isLoading && <Footer />}
      </div>
    </ThemeProvider>
  );
}

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
