import React, { useState, useRef } from 'react';
import Navbar from '../components/Navbar';
import Home from '../components/Home';
import HRIS from '../components/HRIS';
import Track from '../components/Track';
import './style.css';

const Dashboard = () => {
  const [searchValue, setSearchValue] = useState('');
  const trackSectionRef = useRef(null);

  const handleSearch = (value) => {
    setSearchValue(value);
    
    // Scroll to the Track section when a search is performed
    if (trackSectionRef.current) {
      trackSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Navbar onSearch={handleSearch} />
      <div className="dashboard">
        <div className="section">
          <Home />
        </div>
        <div className="section">
          <HRIS />
        </div>
        <div className="section" ref={trackSectionRef}>
          <Track searchValue={searchValue} />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
