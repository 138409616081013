import React, { useEffect } from 'react';
import { FaFacebookF, FaYoutube } from 'react-icons/fa';

const Footer = () => {
  useEffect(() => {
    // Raindrop animation code
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const options = {
      waveLength: 200,
      canvasWidth: window.innerWidth,
      canvasHeight: 50,
      color: '#1c1f2f',
      frequency: 3,
      waveHeight: 50,
      density: 0.02,
      rippleSpeed: 0.1,
      rightPadding: 20,
    };
    const springs = Array(options.waveLength).fill().map(() => ({ p: 0, v: 0 }));

    const updateSprings = (spread) => {
      for (let i = 0; i < options.waveLength; i++) {
        springs[i].v += (-options.rippleSpeed * springs[i].p - options.density * springs[i].v);
        springs[i].p += springs[i].v;
      }

      const leftDeltas = [];
      const rightDeltas = [];
      for (let t = 0; t < 8; t++) {
        for (let i = 0; i < options.waveLength; i++) {
          if (i > 0) {
            leftDeltas[i] = spread * (springs[i].p - springs[i - 1].p);
            springs[i - 1].v += leftDeltas[i];
          }
          if (i < options.waveLength - 1) {
            rightDeltas[i] = spread * (springs[i].p - springs[i + 1].p);
            springs[i + 1].v += rightDeltas[i];
          }
        }
        for (let i = 0; i < options.waveLength; i++) {
          if (i > 0) springs[i - 1].p += leftDeltas[i];
          if (i < options.waveLength - 1) springs[i + 1].p += rightDeltas[i];
        }
      }
    };

    const renderWaves = () => {
      ctx.clearRect(0, 0, options.canvasWidth, options.canvasHeight);
      ctx.beginPath();
      ctx.moveTo(0, options.canvasHeight);
      for (let i = 0; i < options.waveLength; i++) {
        ctx.lineTo(i * (options.canvasWidth / options.waveLength), (options.canvasHeight / 2) + springs[i].p);
      }
      ctx.lineTo(options.canvasWidth, options.canvasHeight);
      ctx.fill();
    };

    const raindropsAnimationTick = () => {
      if (Math.random() * 100 < options.frequency) {
        springs[Math.floor(Math.random() * options.waveLength)].p = options.waveHeight;
      }
      updateSprings(0.1);
      renderWaves();
      requestAnimationFrame(raindropsAnimationTick);
    };

    // Set up canvas
    canvas.width = options.canvasWidth;
    canvas.height = options.canvasHeight;
    ctx.fillStyle = options.color;
    document.getElementById('waterdrop').appendChild(canvas);
    raindropsAnimationTick();
  }, []);

  return (
    <div className="page-wrapper" style={pageWrapperStyle}>
      <div id="waterdrop" style={waterdropStyle}></div> {/* Raindrop animation canvas */}
      <footer style={footerStyle}>
        <div className="footer-bottom" style={footerBottomStyle}>
          <div style={containerStyle}>
            <div style={footerSiteInfoStyle}>
              &copy; 2024 Department of Education - Division of Zamboanga del Sur. All rights reserved. SURE sa Sur 💙
            </div>
            <div className="socials" style={socialsStyle}>
              <a href="https://www.facebook.com/DepEdZDSDIO" target="_blank" rel="noopener noreferrer" style={socialItemStyle}>
                <FaFacebookF />
              </a>
              <a href="https://www.youtube.com/@depedzamboangadelsur190" target="_blank" rel="noopener noreferrer" style={socialItemStyle}>
                <FaYoutube />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

const pageWrapperStyle = {
  position: 'relative',
  width: '100%',
};

const footerStyle = {
  backgroundColor: '#181828',
  padding: '10px 0',
  textAlign: 'center',
  position: 'relative',
};

const footerBottomStyle = {
  padding: '10px 0',
  borderTop: '1px solid #313646',
  color: '#b0b0b0',
  backgroundColor: '#1c1f2f',
};

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  maxWidth: '1000px',
  margin: '0 auto',
};

const footerSiteInfoStyle = {
  fontSize: '0.85em',
  marginRight: '20px',
};

const socialsStyle = {
  display: 'flex',
};

const socialItemStyle = {
  display: 'inline-block',
  textAlign: 'center',
  transition: 'all 0.3s',
  margin: '0 5px',
  lineHeight: '16px',
  padding: '10px',
  borderRadius: '50%',
  backgroundColor: '#141421',
  border: '1px solid #2e2e4c',
  boxShadow: '3px 9px 16px rgba(0, 0, 0, 0.4), -3px -3px 10px rgba(255, 255, 255, 0.06), inset 14px 14px 26px rgba(0, 0, 0, 0.3), inset -3px -3px 15px rgba(255, 255, 255, 0.05)',
  color: 'white',
  fontSize: '1.2em',
};

const waterdropStyle = {
  height: '50px',
  width: '100%',
  overflow: 'hidden',
  position: 'absolute',
  bottom: '0',
};

export default Footer;
