import React from 'react';
import './Card.css';

const Card = ({ imageSrc, title, description, price, duration }) => {
  return (
    <div className="nft">
      <div className="main">
        <img className="tokenImage" src={imageSrc} alt="NFT" />
        <h2>{title}</h2>
        <p className="description">{description}</p>
        <div className="tokenInfo">
          <div className="price">
            <ins>◘</ins>
            <p>{price}</p>
          </div>
          <div className="duration">
            <ins>◷</ins>
            <p>{duration}</p>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default Card;
