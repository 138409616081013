import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../Pages/Dashboard"; // Adjust the path according to your project structure
import UnderConstruction from "../Pages/404/UnderConstruction";

const CustomRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/under-construction" element={<UnderConstruction />} />

    </Routes>
  );
};

export default CustomRoutes;
