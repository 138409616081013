import React from 'react';
import './UnderConstruction.css'; // Make sure to create and import the CSS file

const UnderConstruction = () => {
  return (
    <div className="under-construction">
      <div className="noise"></div>
      <div className="overlay"></div>
      <div className="terminal">
        <h1>Page is <span className="errorcode">Under Construction</span></h1>
        <p className="output">The page you are looking for is currently in development phase.</p>
        <p className="output">Do you want to <a href="/">return to the homepage</a>.</p>
        <p className="output">Thank you for understanding.</p>
      </div>
    </div>
  );
};

export default UnderConstruction;
