import React from 'react';
import './loader.css';
import zamboSur from './zambo sur-min.svg'

const Loader = () => {
  return (
    <div className="loader">
      <div className="svg-wrapper">
        <img src={zamboSur} alt="Loader" className="loader-image" />
        <div className="loading-bar-wrapper">
          <div className="loading-bar"></div>
        </div>
        <div className="loading-text">Loading...</div>
      </div>
    </div>
  );
};

export default Loader;
